<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 15:10:44
 * @LastEditors: DonWangWQ lc819781590@gmail.com
 * @LastEditTime: 2023-05-17 16:50:07
-->
<template>
    <div class="exception-page">
        <div class="header-menu">
            <span>
                <img src="@/assets/images/sidebar/report_leftber_Info@2x.png" alt class="menu-info"
                    @click.prevent="openSide" />
            </span>
        </div>
        <exception-page :exception="exception"></exception-page>
        <!--侧边栏-->
        <van-popup v-model="popupVisible" position="left" :style="{ height: '100%', width: '70%' }">
            <SideBar />
        </van-popup>
    </div>
</template>

<script>
import ExceptionPage from '@/components/exception/Exception'
import image from '@/assets/images/excep_blankpage_def.png'
import SideBar from '@/components/sidebar/index'

export default {
    components: {
        ExceptionPage,
        SideBar
    },
    data() {
        return {
            exception: {
                title: this.$t('reportEmpty.title'),
                desc: this.$t('reportEmpty.desc'),
                loader: false,
                picture: image
            },
            popupVisible: false,
        }
    },
    computed: {

    },
    methods: {
        // 展开侧边栏
        openSide() {
            this.$store.commit("setSidebarData", this.massInfo)
            this.popupVisible = true
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .exception-wrapper>p.title {
    margin-bottom: 12px;
}

/deep/ .exception-wrapper>p {
    padding: 0 32px 0 32px;
}

.header-menu {
    position: relative;
    display: flex;
    padding: 20px 15px;
    width: 100%;
    top: 0;
    z-index: 100;

    >span {
        img {
            display: block;
            width: 26px;
            height: 26px;
        }
    }
}
</style>
